* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.link{
  color: #ffffff;
    text-decoration: none;
}

.footerContainer {
  background-color: #4b7eb2;
  width: 100%;
  height: 14.5vw;
  position: relative;
}

.footer span {
  background-color: #4b7eb2 !important;
}

.footer {
  width: 87vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7vw;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: hidden;

}

.footer .right {
  display: flex;
  align-items: center;
}

.footer .sunken {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7vw;
  line-height: 2.5vw;
  color: #ffffff;
  margin-bottom: 2.5vw;
  cursor: pointer;
}

.footer span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25vw;
  line-height: 2.2vw;
  color: #ffffff;
  margin-right: 3.8vw;
  cursor: pointer;
}

.footer .fbicon,
.footer .twittericon,
.footer .instaicon {
  font-size: 2vw;
  color: #ffffff;
  margin-right: 2vw;
  cursor: pointer;
}

.footer .logo {
  font-size: 8.4vw;
}

@media only screen and (max-width: 600px) {
  .footer .sunken {
    font-size: 4vw;
  }

  .footerContainer {
    height: 25vw;
  }
  .footer{
    /* overflow-y: auto; */
height: fit-content;
  }

  .footer span {
    font-size: 2.5vw;
    line-height: 2.2vw;
    margin-right: 2vw;
  }

  .footer .fbicon,
  .footer .twittericon,
  .footer .instaicon {
    font-size: 4vw;
    margin-right: 2vw;
  }

  .footer .left .sunken{
height: fit-content;
overflow-y: hidden;

  }

  .footer .logo {
    height: 18vw;
  }
}
