.about_cont{
    /* width: 100%; */
    background-color: #0F1B33;
    padding: 2% 10%;
}

.about-h{
    font-size: 35.5px;
	font-family: "Poppins";

    font-weight: 800;
    color: #54BBEE;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.about{
    color: white;
	font-family: "Poppins";

    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}
@media only screen and (max-width: 600px) {
    .about-h{
        font-size: 8vw;
        top: 50px;
        text-align: center;
    }
    .about{
        font-size: 3vw;
    }
}