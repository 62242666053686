* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x : hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Poppins:wght@200;400&display=swap");

.eventsBackground {
  background-color: #0e1b33;
  background-image: url("./EventsBgSVG.svg");
  height: 100vh;
  width: 100%;
}

.events {
  padding-top: 9.4vw;
}

.events .heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vw;
  line-height: 160%;
  color: #54bbee;
  width: 13.5vw;
  height: 4.38vw;
  text-align: center;
  border-bottom: solid 4px #54bbee;
  margin-left: auto;
  margin-right: auto;
  animation: fadeInEffect 2s;
}

.events .tabsContent {
  display: none;
  animation: fadeOutEffect 2s;
}

@keyframes fadeOutEffect {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.events .showTab {
  display: block;
  animation: fadeInEffect 2s;
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.events .tabsContainer {
  display: flex;
  justify-content: center;
  margin-top: 5.55vw;
  align-items: center;
}

.events .tabsContainer .tabs {
  width: 29.2vw;
  margin-right: 7.08vw;
  cursor: pointer;
  animation: fadeInEffect 2s;
}

.events .tabsContainer .tabs > div {
  display: flex;
  align-items: center;
  height: 4.5vw;
  border-right: solid 1px #c4c4c4;
  transition: all 0.4s;
}

.events .tabsContainer .tabs .activeTab {
  border-right: solid 4px #54bbee;
  transition: all 0.4s;
}

.events .tabsContainer .tabs .activeTab .tabName {
  color: #54bbee;
  transition: all 0.4s;
}

.events .tabsContainer .tabs .icon {
  line-height: 2.1vw;
  color: #dddce0;
  margin-right: 2.9vw;
  font-size: 3vw;
}

.events .tabsContainer .data {
  width: 39.7vw;
}

.events .tabs .tabName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.39vw;
  line-height: 2.1vw;
  color: #dddce0;
  width: 100%;
  transition: all 0.4s;
}

.events .data .dataIcon {
  font-size: 5.76vw;
  color: #dddce0;
  width: 100%;
  text-align: center;
  margin-bottom: 2vw;
}

.events .data .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2.2vw;
  line-height: 3.3vw;
  color: #54bbee;
  width: 100%;
  text-align: center;
}

.events .data .subtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1vw;
  line-height: 1.6vw;
  color: #dddce0;
  width: 100%;
  text-align: center;
  margin-bottom: 2.5vw;
}

.events .data .desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-size: 1.1vw;
  line-height: 1.6vw;
  color: #dddce0;
}

.eventsMobile, .eventsMobileBackground {
  display: none;
}

@media only screen and (max-width: 600px) {
  .eventsMobileBackground {
    display: block;
    background-color: #0e1b33;
    background-image: url("./EventsBgSVG.svg");
    background-size: cover;
    width: 100%;
    height: 250vw;
  }

  .eventsMobile {
    width: 100%;
    display: block;
    left: 50%;
    padding-top: 28vw;
  }

  .events, .eventsBackground {
    display: none;
  }

  .eventsMobile .heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 6.66vw;
    line-height: 160%;
    color: #54bbee;
    border-bottom: solid 3px #54bbee;
    width: 21.9vw;
    height: 10.5vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15.8vw;
    animation: fadeInEffect 2s;
    overflow-y: hidden;
  }

  .eventsMobile .data {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
  }

  .eventsMobile .data .dataIcon {
    font-size: 15vw;
    color: #dddce0;
    width: 100%;
    text-align: center;
    margin-bottom: 6.6vw;
  }

  .eventsMobile .data .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 6.66vw;
    line-height: 10vw;
    color: #54bbee;
    width: 100%;
    text-align: center;
  }

  .eventsMobile .data .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 3.3vw;
    line-height: 5vw;
    color: #dddce0;
    width: 100%;
    text-align: center;
    margin-bottom: 6.6vw;
  }

  .eventsMobile .data .desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 200;
    font-size: 3.3vw;
    line-height: 5vw;
    color: #dddce0;
  }

  .eventsMobile .tabsContent {
    display: none;
  }

  .eventsMobile .showTab {
    display: block;
    animation: fadeInEffect 2s;
  }

  .eventsMobile {
    
  }

  .eventsMobile .tabs {
    width: 52.7vw;
    height: 53.6vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 26vw;
    animation: fadeInEffect 2s;
  }

  .eventsMobile .tabName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 4.16vw;
    line-height: 9.5vw;
    color: #dddce0;
    transition: all 0.4s;
  }

  .eventsMobile .icon {
    font-size: 6.9vw;
    color: #dddce0;
    margin-right: 12vw;
  }

  .eventsMobile .tabs > div {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  .eventsMobile .tabs .activeTab .tabName {
    color: #54bbee;
    transition: all 0.4s;
  }

  .eventsMobile .leftarrow {
    color: #3734a9;
    background-color: white;
    border-radius: 50%;
    font-size: 8.6vw;
    position: absolute;
    top: 90vw;
    right: 87vw;
    cursor: pointer;
    animation: fadeInEffect 2s;
  }

  .eventsMobile .leftarrow:active,
  .eventsMobile .rightarrow:active {
    color: #ffffff;
    background-color: #000000;
  }

  .eventsMobile .rightarrow {
    color: #3734a9;
    background-color: white;
    border-radius: 50%;
    font-size: 8.6vw;
    position: absolute;
    top: 90vw;
    left: 87vw;
    cursor: pointer;
    animation: fadeInEffect 2s;
  }

  .line span {
    display: block;
    width: 20%;
    float: left;
    height: 1px;
  }
  .line {
    width: 79vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vw;
    transform: scaleY(-1);
    animation: fadeInEffect 2s;
  }
  .color-0 {
    background: #ffffff;
    height: 1px;
    transition: all 0.4s;
  }
  .color-1 {
    background: #ffffff;
    height: 1px;
    transition: all 0.4s;
  }
  .color-2 {
    background: #ffffff;
    height: 1px;
    transition: all 0.4s;
  }
  .color-3 {
    background: #ffffff;
    height: 1px;
    transition: all 0.4s;
  }
  .color-4 {
    background: #ffffff;
    height: 1px;
    transition: all 0.4s;
  }
}
