.rules-container {
    background-color: #191919;
    min-height: 100vh;
    width: 100%;
    background-color: #0F1B33;
    overflow-x: hidden;
  }
  
  .rules-h{
    color: #54BBEE;
    text-align: center;
    font-size: 3rem;
    margin: 1.5rem;
    font-weight: 700;
    margin-top: 6rem;
  }

  
  #acc {
    background-color: #292929;
    background-color: rgb(25, 46, 84);
    color: white;
    padding: 1.5rem;

  }
    
  .accordion-item {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
    border: none;
    font-family: Avenir LT Std;
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
  
  }
  .accordion-body {
    background-color: #292929;
    background-color: rgb(25, 46, 84);

    border: none;
    color: #9c9c9c;
  }
  .accordion-button:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  }
  #acc {
    font-family: Avenir LT Std;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    padding-left: 29px;
    padding-top: 14.5px;
    padding-right: 29px;
    padding-bottom: 14.5px;
  }
  
  #accordion-item {
    border: none;
  }
  
  #accordion-item::focus {
    outline: none;
  }

  .bubble-bg{
position: absolute;
opacity: 0.3;
left:0;
top:25rem;
width:8rem;
  }

  .right-bg{
    position: absolute;
    opacity: 0.5;
    right:0;
    top:16rem;
    width:18rem;
    overflow-y: hidden;

  }

@media only screen and (max-width: 600px) {
.rules-h{
  font-size: 8vw;
  top: 50px;
  text-align: center;
  margin-bottom: 5vw;
}
.accordion-item {
width: 90vw;
margin-left: 5vw;
font-size: 3.5vw;
}

.bubble-bg,.right-bg{
  visibility: hidden;
  display: none;
}

#acc {
font-size: 4.5vw;
}
}