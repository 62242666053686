@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Poppins:wght@200;400&display=swap");

.FAQ_cont{
    /* height: 110vh; */
    width: 100%;
    background-color: #0F1B33;
}

.faq-home-h{
    font-size: 48px;
	font-family: "Poppins";

    font-weight: 800;
    color: #54BBEE;
    text-align: center;
    margin-bottom: 3rem;
    padding-top: 2rem;
}


.faq-card{
    width: 70%;
    margin-left: 15%;
    border-radius: 15px;
    background-color: rgb(25, 46, 84,0.4);
    padding: 1rem;
	font-family: "Poppins";

    margin-bottom: 2rem;
    
}

.faq-card-q{
    color: white;
    font-size: 1.4rem;
    font-weight: 700;
	font-family: "Poppins";

    color: #54BBEE;

    text-align: center;
    margin-bottom: 0.5rem;
}

.faq-card-ans{
    color: white;
	font-family: "Poppins";

    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 1rem;

}

.faq-rm{
    padding: 0.5rem 1rem;
    margin-left: 50%;
    transform: translateX(-50%);
    border: none;
	font-family: "Poppins";

    background-color: #C2D8E8;
    color: #0D0B62;
    font-size: 1.2rem;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 5rem;
}

.faq-rm:hover{
    cursor: pointer;
}


.faqhbg{
    width: 100%;
    position: absolute;
    opacity: 0.2;
    margin-top: 20rem;
}
@media only screen and (max-width: 600px) {
.faq-home-h{
    font-size: 8vw;
    top: 50px;
    text-align: center;
    margin-bottom: 5vw;
}
.faq-card{
    width: 90vw;
    margin-left: 5vw;
}
.faqhbg{
    display: none;
    visibility: hidden;
}

.faq-card-q{
    font-size: 5vw;
}
.faq-card-ans{
    font-size: 3vw;
}
}
