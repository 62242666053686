.contact-container {
    height: 75vh;
    background-color: #0F1B33;
    width: 100%;
  }
  .contactus-h {
    /* top: 20px; */
    /* font-family: "Poppins"; */
    font-size: 48px;
    font-weight: 800;
    color: #54BBEE;
    text-align: center;
	font-family: "Poppins";

    margin-bottom: 8rem;
    padding-top: 2rem;
  }
  
  .c-box {
    /* background-color: antiquewhite; */
    width: 200px;
    align-items: center;

    justify-content: center;
  }
  .contact-img {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 85px;
    height: 85px;
  }
  
  .contact-img:hover {
    opacity: 0.5;
  }
  
  
  .c-box-text {
    font-family: "Roboto";
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: white;
	font-family: "Poppins";
text-decoration: none;
    margin-top: 15px;
  }
  .contact-row {
    width: 70%;
	font-family: "Poppins";

    margin-left: 15%;
    top: 190px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: aliceblue; */
  }
  
  .footer-c {
    /* position: absolute; */
    height: 260px;
    bottom: 0;
    left: 0;
    background: #292929;
    width: 100%;
    text-align: center;
    align-items: center;
    width: 100vw;
  }
  
  .logo-img {
    height: 68px;
    width: 68px;
    /* position: absolute; */
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .footer-content {
    width: 548px;
    height: 52px;
    /* position: absolute; */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    top: 113px;
    color: #ffffff;
    font-size: 16px;
  }
  
  .footer-icon {
    margin: 0px 15px;
    margin-top: 200px;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
    border: none;
  
  }
  
  .footer-icon:hover{
    background-color: gray;
  }
  
  @media only screen and (max-width: 600px) {

    .contactus-h {
      font-size: 8vw;
      top: 50px;
      text-align: center;
      margin-bottom: 5vw;
    }
    .contact-h {
      flex-direction: column;
      width: 100%;
    }
  
.contact-container {
height: fit-content;
}
    .c-box {
      /* background-color: antiquewhite; */
      width: 100%;
      overflow-x: hidden;
    }
    .contact-img {
      margin-left: 50%;
      transform: translateX(-50%);
      width: 55px;
      height: 55px;
      color: #ffb800;
    }
    
      
    .logo-img {
      height: 55px;
      width: 55px;
      /* position: absolute; */
      top: 25px;
      left: 50%;
      transform: translateX(-50%);
    }
    .c-box-text {
      font-family: "Roboto";
      font-weight: 800;
      font-size: 18px;
      text-align: center;
      color: white;
      margin-top: 10px;
      margin-bottom: 30px;
    }
    .contact-row {
      max-width: 600px;
      overflow-x: hidden;
      flex-direction: column;
      display: flex;
    }
  

  }
  
  
  a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
}
