@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500&family=Poppins:wght@200;400&display=swap");

.navMob {
  display: none;
}

.link{
  color: #ffffff;
    text-decoration: none;
}

.link:hover{
  color: white;
}

.navbar {
  position: absolute;
  display: flex;
  align-items: center;
  width: 80vw;
  left: 50%;
  top:1vw;
  transform: translate(-50%, 0);
}

.navbar .navHome,
.navbar .navEvents,
.navbar .navFAQ {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 1.2vw;
  line-height: 1.67vw;
  color: #ffffff;
  margin-left: 2.3vw;
}

.navbar .navImg{
  width:6.5vw;
  height:6.5vw;
}

.navbar .navRegister {
  font-family: "Poppins";
  color: #3734a9;
  background: #c2d8e8;
  font-weight: 500;
  font-size: 1.2vw;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 1.04vw;
  width: 11.6vw;
  height: 3vw;
  line-height: 3vw;
  text-align: center;
  margin-left: 45vw;
}

@media only screen and (max-width: 600px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .navbar {
    display: none;
  }

  .navMob {
    display: block;
  }

  .navMob .head {
    position: absolute;
    color: white;
    width: 84.7vw;
    height: 10vw;
    display: flex;
    align-items: center;
    margin-top: 7.5vw;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .navMob .head .hamburger {
    font-size: 7vw;
    color: #54bbee;
    cursor: pointer;
  }

  .navMob .head .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 6.67vw;
    line-height: 10vw;
    color: #ffffff;
    margin-left: 24.5vw;
  }
  .navMob .slider {
    position: absolute;
    width: 45.33vw;
    font-family: "Poppins";
    font-style: normal;
    height: 100%;
    font-weight: 300;
    font-size: 5.5vw;
    line-height: 6.6vw;
    color: #ffffff;
    background-color: black;
    z-index: 1;
    left: -45.33vw;
    transition: all 0.4s;
  }
  html {
    position: relative;
  }
  .navMob .slider .cross {
    font-size: 7vw;
    color: #3734a9;
    cursor: pointer;
    margin-top: 7.5vw;
    margin-left: 7.65vw;
    margin-bottom: 8vw;
  }
  .navMob .slider .nav {
    margin-bottom: 8vw;
    width: 33.33vw;
    text-align: center;
    margin-left: 6vw;
    height: 10.5vw;
    line-height: 10.5vw;
    cursor: pointer;
  }

  .navMob .slider .nav:active {
    background-color: #3734a9;
    border-radius: 4.1vw;
  }

  .navMob .slider .register {
    margin-left: 6vw;
    color: #3734a9;
    background: #c2d8e8;
    font-weight: 500;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4.1vw;
    width: 33.33vw;
    height: 10.5vw;
    line-height: 10.5vw;
    text-align: center;
  }
}
