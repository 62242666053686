


@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Poppins:wght@200;400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

  
  * {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
  }
 
  :root {
	--smaller: .75;
  }
  
  .container2 {
	align-items: center;
	font-family: "Poppins";

	color: #111;
	margin: 0;
	text-align: center;
	background-color: #0e1b33;
	height: auto;
	padding-top: 25px;
  }
  
  .container2 h1 {
	font-weight: normal;
	overflow-y: hidden;
	color: white;
	font-weight: 700;
	letter-spacing: .125rem;
	background-color: #0e1b33;
}
  
  .container2 li {
	display: inline-block;
	font-size: 1.2em;
	list-style-type: none;
	padding: 1em;
	text-transform: uppercase;
  }
  
  .container2 li span {
	display: block;
	font-size: 3.5rem;
  }
  

  @media all and (max-width: 768px) {
	.container2	h1 {
	  font-size: calc(1.25rem * var(--smaller));
	}
	
	.container2	li {
	  font-size: calc(1.1rem * var(--smaller));
	}
	
	.container2	li span {
	  font-size: calc(3.1rem * var(--smaller));
	}
  }

 
  #countdown{
	  background-color: #FFF;
	  width: max-content;
	  margin-left: 50%;
	  transform: translateX(-50%);
	  padding:0.5rem 5rem ;
	  border-radius: 2rem;
  }