.eventscontainer {
  display : block;
  background-color: #0f1b33;
  height: 420vh;
  overflow : hidden;
}

@media only screen and (max-width: 600px) { 
    .eventscontainer {display: none;} 
} 

.mapway {
  position: absolute;
  width: auto;
  height: 121vh;
  left: 13.847%;
  top:calc(116.4 + 50vh);
}

.iota {
  position: absolute;
  width: auto;
  right: 14.561vw;
  height: 118.3055vh;
  top:calc(325vh + 50vh);
}
.designhack {
  position: absolute;
  width: auto;
  left: 8.793%;
  top:calc(266vh + 50vh);
  height: 121.3313vh;
}
.scoutabout {
  position: absolute;
  width: auto;
  right: 14.561vw;
  top:calc(385.022vh + 50vh);
  height: 118.3055vh;
}
.hack4cause {
  position: absolute;
  width: auto;
  height: auto;
  left: 11.4932%;
  top:calc(461.2708vh + 50vh);
}
.mapwayheading {
  display: flex;
  flex-direction: column;
  position: relative;
}
.head {
  font-size: 3vw;
  color: white;
}
.text {
  font-size: 1.25vw;
  color: white;
}
.iotaheading {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 33.37%;
  height: 152px;
  left: 16.47899%;
  top:calc(225vh + 50vh);
}

.designhackheading {
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 32.61095%;
  height: 157px;
  left: 55.8736%;
  top:calc(300vh + 50vh);
}

.scoutaboutheading {
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 28.4566%;
  height: 145px;
  left: 18.07066%;
  top:calc(415vh + 50vh);
}
.hack4causeheading {
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 36.9721%;
  height: 118px;
  left: 46.6802%;
  top:calc(510vh + 50vh);
}
img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}
.statue {
  position: absolute;
  width: 15.8857vw;
  height: 45.6732;
  top:calc(300vh);
}
.statue2 {
  position: absolute;
  width: 16.604vw;
  height: 50.5582vh;
  left: 75.0366vw;
  top:calc(504.818vh + 50vh);
}
.p1-1 {
  position: absolute;
  left: 24.93%;
  z-index: 2;
  top:calc(350vh + 50vh);
  width: 78.21px;
  height: 37.01px;
}
.p1-2 {
  position: absolute;
  left: 25.05%;
  top:calc(351vh + 50vh);
  width: 77.94px;
  height: 36.44px;
}
.p2-1 {
  position: absolute;
  left: 29.73%;
  z-index: 2;
  top:calc(360vh + 50vh);
  width: 75.51px;
  height: 40.08px;
}
.p2-2 {
  position: absolute;
  left: 29.67%;
  width: 77.85px;
  height: 43.21px;
  top:calc(361vh + 50vh);
}

.p3-1 {
  position: absolute;
  left: 36.91%;
  width: 78.39px;
  height: 38.83px;
  z-index: 2;
  top:calc(372vh + 50vh);
}
.p3-2 {
  position: absolute;
  left: 36.89%;
  width: 80.55px;
  height: 39.1px;
  top:calc(373vh + 50vh);
}

.p4-1 {
  position: absolute;
  left: 46.23%;
  width: 78.39px;
  height: 38.83px;
  z-index: 2;
  top:calc(376vh + 50vh);
}
.p4-2 {
  position: absolute;
  left: 46.21%;
  width: 80.55px;
  height: 39.1px;
  top:calc(377vh + 50vh);
}

.p5-1 {
  position: absolute;
  left: 51.41%;
  z-index: 2;
  top:calc(388vh + 50vh);
  width: 78.21px;
  height: 37.01px;
}
.p5-2 {
  position: absolute;
  left: 51.55%;
  top:calc(389.3vh + 50vh);
  width: 77.94px;
  height: 36.44px;
}



.leftside, .rightside {

  height : 50vh;
  width: 100%;
}

.text-timeline-align-rightside {
margin-top: 30%;
padding-right: 20%;
}

.text-timeline-align-leftside {
  margin-top: 30%;
  padding-left: 20%;
  }

  @media only screen and (max-width: 600px) {

  }