@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&family=Poppins:wght@200;400&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.landingBg {
  background-color: #0e1b33;
  height: 70vw;
  width: 100%;
  background-image: url("./landing.svg");
  background-repeat: no-repeat;
}

.landing {
  display: flex;
  align-items: center;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12.43vw;
  justify-content: space-between;
}

.landingTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 6.66vw;
  line-height: 10vw;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.landingSub {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 2.92vw;
  line-height: 140.5%;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 6.04vw;
}

.landingCalendar {
  display: flex;
  align-items: center;
}

.landingIcon {
  color: #00a7ff;
  font-size: 1.94vw;
}

.landingDate {
  width: 12.9vw;
  margin-left: 1.04vw;
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-size: 1.25vw;
  line-height: 1.875vw;
  text-transform: uppercase;
  color: #ffffff;
}

.landingText2 {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-size: 1.11vw;
  line-height: 1.66vw;
  letter-spacing: 0.065em;
  color: #ffffff;
  margin-top: 0.5vw;
}

.landingButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.75vw;
}

.landingRegister {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 11.5vw;
  height: 3.33vw;
  background-color: #c2d8e8;
  border-radius: 1.04vw;
  color: #0d0b62;
  cursor: pointer;
}

@media only screen and (max-width: 600px){
  .landingRegister {margin-left : 52px}
}

.landRegButton {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-size: 1.11vw;
  line-height: 1.3vw;
  color: #0d0b62;
}

.landingArrow {
  margin-left: 0.1vw;
  font-size: 1.54vw;
}

.landRegButton:hover {
  margin-right: 5px;
  transition: all 0.2s;
}

.landingAdd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14vw;
  height: 3.33vw;
  border-radius: 1.04vw;
  background-color: #54bbee;
  color: #0f1b33;
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-size: 1.11vw;
  line-height: 19px;
}

.landingbuttonCal {
  margin-right: 0.7vw;
}

.landingImg {
  width: 45.34vw;
}

.landingSubmarine {
  height: 26.25vw;
  width: 41.5vw;
}

.landingBlocks {
  width: 45.34vw;
  height: 15.7vw;
}

@media only screen and (max-width: 600px) {
  .landing *{
    overflow-y: hidden;
  }
  .landingBg {
    height: 120vw;
  }
  .landing {
    padding-top: 28vw;
    display: block;
  }

  .landingTitle{
    font-size:10vw;
    height: fit-content;
  }

  .landingSub{
    margin-top: 2vw;
    font-size: 4vw;
    height: fit-content;

  }

  .landingIcon{
    font-size: 4vw;
  }

  .landingDate{
    width: 20vw;
    font-size: 2.5vw;
  }

  .landingText2{
    margin-top: 2vw;
    font-size: 2vw;
    margin-bottom: 2.5vw;
  }
  .landingImg {
    margin-top: 5vw;
    margin-left: auto;
    margin-right: auto;
  }

  .landingText{
    width: 100%;
    text-align: center;
  }

  .landingCalendar{
    width: 27vw;
    margin-left: auto;
    margin-right: auto;
  }

  .landingButtons {
    margin-top: 4vw;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    width: 50vw;
  }

  .landingRegister{
    width: 20vw;
    height: 5vw;
    border-radius: 2vw;
  }
  .landRegButton{
    font-size: 2vw;
  }

  .landingArrow{
    font-size: 3.5vw;
  }

  .landingAdd{
    width: 28vw;
    height: 5vw;
    border-radius: 2vw;
    font-size: 2.5vw;
  }

  .landingbuttonCal{
    font-size: 3.5vw;
  }

  .landingBlocks{
    margin-top: -6vw;
  }
}
